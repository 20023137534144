import React, { useContext, useEffect, useState } from "react";
import { AccountContextInterface } from "../types";
import { DashboardSectionInfo } from "./dashboard";
import { AppTitle, ContentBox, deviceSize } from "./common";
import styled from "styled-components";
import { AccountContext } from "../contexts/accountContext";
import ReactCalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import { useParams } from 'react-router-dom';
import { VIDEOS } from "../video-links";

const FullYearTestNames = {
    "10": "April 2015",
    "11": "April 2016",
    "12": "April 2017",
    "13": "April 2018",
    "14": "April 2019",
    "25": "October 2015",
    "26": "October 2016",
    "27": "October 2017",
    "28": "October 2018",
    "29": "October 2019",
    "30": "October 2020 AM",
    "31": "October 2020 PM",
    "76": "April 2021",
    "77": "October 2021 Exam",
    "79": "April 2022 Exam",
    "80": "October 2022 Exam",
    "81": "April 2023 Exam (very difficult exam)",
    "82": "October 2023 Exam",
    "83": "April 2024 (May 1st) Exam",
    "84": "October 2024 Exam"
}


const TestNames = {
    "32": "AD CVD",
    "33": "CF 7501 Questions",
    "34": "Ch 1 US Customs Service",
    "35": "Ch 2 Basic Entry Process",
    "36": "Ch 3 Basic Entry Requirements",
    "37": "Ch 4 Special Entry Procedures and AD",
    "38": "Ch 5 Bonds and Bond Directive",
    "39": "Ch 6 Liquidation and Currency Conversion",
    "40": "Ch 7 Inspection, GO, Short Shipments",
    "41": "Ch 8 CFR 24",
    "42": "Ch 9 Valuation and Appraisement",
    "43": "Ch 10 Customs Brokers",
    "44": "Ch 11 Transportation Entries",
    "45": "Ch 12 Prohibited and Restricted Merchandise",
    "46": "Ch 13 Quotas",
    "47": "Ch 14 Trade Name - Trade Mark",
    "48": "Ch 15 Rules of Origin",
    "49": "Ch 16 Marking",
    "50": "Ch 17 Warehouse Entries",
    "51": "Ch 18 AD CV and Evasion",
    "52": "Ch 19 NAFTA",
    "53": "Ch 20 Rulings, PSC's, and Correcting Errors",
    "54": "Ch 21 Protest",
    "55": "Ch 22 Petition for Relief",
    "56": "Ch 23 Foreign Trade Zone",
    "57": "Ch 24 Drawback",
    "58": "Ch 25 Record Keeping",
    "59": "Ch 26 Carnets and Mail Entries",
    "60": "Ch 27 GRI's, GN's, and GSN's combined",
    "61": "Ch 28 Classification",
    "62": "Ch 29 HTS Ch 98 and TIB",
    "65": "ACE and Directives Questions",
    "66": "Export and 192",
    "67": "Trade Program Questions",
    "68": "HTS - General Rules of Interpretation",
    "70": "Math Valuation Qs",
    "72": "Multi Fact Pattern Questions",
    "73": "Penalties and CFR 162",
    "74": "Random Ch. 10",
    "75": "Ch 30 Textiles",
    "78": "Ch 19 USMCA"
}

const VideoNames = VIDEOS.reduce((acc, section) => {
    section.videos.forEach(video => {
        acc[video.id] = video.title;
    });
    return acc;
}, {} as { [key: string]: string });

const StyledTest = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  @media ${deviceSize.tablet} {
    grid-template-columns: 1fr;
    padding: 0 25px;
  }
  
  @media ${deviceSize.mobileL} {
    grid-template-columns: 1fr;
    padding: 0 10px;
    gap: 15px;
  }
`;

const StyledNoUserActivity = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    font-size: 1rem;
    font-weight: 800;
    width: 100%;
`
const StyledChartHeatmap = styled.div`
    display: grid;
    grid-template-columns: 35fr 65fr;
    grid-template-rows: auto auto 1fr;
    gap: 20px;
    width: 100%;
    padding: 10px;
    position: relative;
    padding-top: 15px;

    > :first-child {
        grid-column: 1 / -1;
        grid-row: 1;
    }

    .react-calendar-heatmap {
        height: 200px;
        grid-row: 2;
    }

    .react-calendar-heatmap text {
        font-size: 12px;
    }

    > :last-child {
        grid-column: 1 / -1;
        grid-row: 4;
    }
    
    @media ${deviceSize.tablet} {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto 1fr;
        padding-top: 10px;
        
        .react-calendar-heatmap {
            height: 160px;
            grid-row: 2;
        }
    }
    
    @media ${deviceSize.mobileL} {
        padding: 5px;
        gap: 10px;
        
        .react-calendar-heatmap {
            height: 120px;
        }
        
        .react-calendar-heatmap text {
            font-size: 10px;
        }
    }
`;

const StyledChartContainer = styled.div`
    display: flex;
    width: 100%;
    height: 800px;
    
    @media ${deviceSize.tablet} {
        height: auto;
        min-height: 500px;
    }
    
    @media ${deviceSize.mobileL} {
        height: auto;
        min-height: 400px;
    }
`;

const StyledDayStats = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
    .date {
        font-size: 1.1em;
        font-weight: 800;
        color: #2c3e50;
        margin-bottom: 10px;
        text-align: center;
    }
    
    .metrics {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        margin-bottom: 15px;
    }
    
    .metric {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
        .value {
            font-size: 1.5em;
            font-weight: 600;
            color: #2c3e50;
            margin-bottom: 2px;
        }
        
        .label {
            font-size: 0.8em;
            color: #7f8c8d;
            line-height: 1.1;
        }
    }
    
    @media ${deviceSize.mobileL} {
        height: 200px;
        padding: 0.5rem;
        
        .metrics {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 5px;
        }
        
        .metric .value {
            font-size: 1.2em;
        }
        
        .metric .label {
            font-size: 0.7em;
        }
    }
`;

type AccountActivities = {
    date: string, count: number
}

type AccountActivity = {
    date: string,
    count: number
    tests: number;
    videos: number;
}

const timestampToDay = (timestamp: number): string => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // add 1 to get 1-12 range
    const day = date.getDate();

    // create a key for the day object in the buckets object
    return `${year}-${month}-${day}`;
}

const groupTimestampsByDay = (timestamps: number[]): AccountActivities[] => {
    const buckets: { [day: string]: number[] } = {};

    timestamps.forEach(timestamp => {
        const key = timestampToDay(timestamp);

        // add the timestamp to the day object in the buckets object
        if (!buckets[key]) {
            buckets[key] = [];
        }
        buckets[key].push(timestamp);
    });
    const data: AccountActivities[] = [];
    Object.keys(buckets).forEach(key => {
        data.push({ date: key, count: buckets[key].length })
    });
    return data;
}

function isWithinTwoMonths(timestamp: number) {
    const current = new Date();
    const date = new Date(timestamp);
    const diffInMonths = (current.getFullYear() - date.getFullYear()) * 12 + current.getMonth() - date.getMonth();

    return diffInMonths <= 2;
}

function subtractTwoMonthsInSeconds(timestamp: number) {
    const monthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const twoMonthsInSeconds = 2 * monthInMilliseconds / 1000;
    const newTimestamp = timestamp - twoMonthsInSeconds * 1000;

    return newTimestamp;
}

const NO_ACTIVITY = "No activity in the last 2 months";
const HOVER_DAY = "Click on a day to see activity details";
const USER_NOT_FOUND = 'No activity data found for this user';
const DEFAULT_TEST_COUNT = 48; // TODO: update this when adding new tests

const CalendarComponent = ReactCalendarHeatmap as any;

const StyledTestReport = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
  @media ${deviceSize.mobileL} {
    margin: 10px 0;
    padding: 10px;
    
    h3 {
        font-size: 1.1em;
    }
  }
`;

const StyledTestHeader = styled.div`
  padding: 10px 15px;
  background: #f8f9fa;
  border-radius: 6px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #2c3e50;
  
  @media ${deviceSize.mobileL} {
    padding: 8px 10px;
    font-size: 0.9em;
  }
`;

const StyledMetricsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  
  @media ${deviceSize.mobileL} {
    padding: 5px 0;
  }
`;

const StyledMetric = styled.div`
  text-align: center;
  
  .value {
    font-size: 1.2em;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .label {
    font-size: 0.9em;
    color: #7f8c8d;
  }
  
  @media ${deviceSize.mobileL} {
    .value {
      font-size: 1em;
    }
    
    .label {
      font-size: 0.8em;
    }
  }
`;

interface TestMetrics {
    questionsAnswered: number;
    percentageComplete: number;
    successRate: number;
    timestamp: number;
    testId: number;
}

const calculateTestMetrics = (test: any): TestMetrics => {
    const questionsAnswered = test.questionsAnswered || 0;
    const totalQuestions = test.questionsTotal || 0;
    const correctAnswers = test.correctAnswers || 0;

    return {
        questionsAnswered,
        percentageComplete: totalQuestions ? (questionsAnswered / totalQuestions) * 100 : 0,
        successRate: questionsAnswered ? (correctAnswers / questionsAnswered) * 100 : 0,
        timestamp: test.timestamp,
        testId: test.testId
    };
};

const TestReport: React.FC<{ tests: any[] }> = ({ tests }) => {
    // Create a map of testId to metrics for quick lookup
    const testMetricsMap = tests
        .map(calculateTestMetrics)
        .reduce((acc, metrics) => {
            acc[metrics.testId] = metrics;
            return acc;
        }, {} as { [key: number]: TestMetrics });

    // Get all test IDs from TestNames, sort them numerically
    const allTestIds = Object.keys(TestNames)
        .map(Number)
        .sort((a, b) => a - b);

    return (
        <StyledTestReport>
            <h3>Category Test Report ({allTestIds.length} Tests)</h3>
            {allTestIds.map((testId) => {
                const metrics = testMetricsMap[testId];
                return (
                    <div key={testId}>
                        <StyledTestHeader>
                            {TestNames[String(testId) as keyof typeof TestNames]}
                        </StyledTestHeader>
                        <StyledMetricsContainer>
                            {metrics ? (
                                <>
                                    <StyledMetric>
                                        <div className="value">{metrics.questionsAnswered}</div>
                                        <div className="label">Questions</div>
                                    </StyledMetric>
                                    <StyledMetric>
                                        <div className="value">{metrics.percentageComplete.toFixed(1)}%</div>
                                        <div className="label">Complete</div>
                                    </StyledMetric>
                                    <StyledMetric>
                                        <div className="value">{metrics.successRate.toFixed(1)}%</div>
                                        <div className="label">Success Rate</div>
                                    </StyledMetric>
                                </>
                            ) : (
                                <StyledMetric>
                                    <div className="value">No Data Found</div>
                                    <div className="label">Not Started</div>
                                </StyledMetric>
                            )}
                        </StyledMetricsContainer>
                    </div>
                );
            })}
        </StyledTestReport>
    );
};

interface ModuleData {
    tests: TestData[];
    videos: VideoData[];
}

interface TestData {
    testId: number;
    questionsAnswered: number;
    questionsTotal: number;
    correctAnswers: number;
    timestamp: number;
    questions: any[]; // You can make this more specific if needed
}

interface VideoData {
    id: string;
    seen: boolean;
    timestamp: number;
}

type UserModuleTestInfo = { [key: string]: ModuleData };

const StyledRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const formatDate = (dateStr: string) => {
    const [year, month, day] = dateStr.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};

const StyledActivityList = styled.div`
    margin-top: 5px;
    font-size: 0.85em;
    height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0 10px;

    .activity-item {
        padding: 3px 0;
        color: #2c3e50;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    @media ${deviceSize.mobileL} {
        height: 150px;
        padding: 0 5px;
        font-size: 0.8em;
    }
`;

const StyledActivityTimeline = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .title {
        font-size: 1.2em;
        font-weight: 800;
        color: #2c3e50;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
    }

    .timeline-content {
        padding: 10px 0;
    }
    
    @media ${deviceSize.mobileL} {
        .title {
            font-size: 1em;
        }
        
        .timeline-content {
            padding: 5px 0;
        }
    }
`;

const StyledTimelineItem = styled.div<{ isHighlighted: boolean }>`
    padding: 8px;
    border-radius: 4px;
    background-color: ${(props: { isHighlighted: boolean }) => props.isHighlighted ? '#f8f9fa' : 'transparent'};
    transition: background-color 0.2s;
    margin-bottom: 5px;

    .date {
        font-weight: 600;
        color: #2c3e50;
        margin-bottom: 5px;
    }

    .activities {
        margin-left: 20px;
    }

    .activity-item {
        padding: 2px 0;
        color: #34495e;
    }
    
    @media ${deviceSize.mobileL} {
        padding: 6px;
        margin-bottom: 3px;
        
        .date {
            font-size: 0.9em;
            margin-bottom: 3px;
        }
        
        .activities {
            margin-left: 10px;
        }
        
        .activity-item {
            font-size: 0.9em;
        }
    }
`;

const ActivityTimeline: React.FC<{
    moduleData: { [key: string]: ModuleData },
    selectedDate: string | null
}> = ({ moduleData, selectedDate }) => {
    // Get all activities and sort by timestamp
    const activities = Object.values(moduleData).flatMap(module => [
        ...module.tests.map(test => ({
            type: 'test',
            timestamp: test.timestamp,
            name: TestNames[String(test.testId) as keyof typeof TestNames] ||
                FullYearTestNames[String(test.testId) as keyof typeof FullYearTestNames] ||
                `Test ${test.testId}`,
            emoji: '📝'
        })),
        ...module.videos.map(video => ({
            type: 'video',
            timestamp: video.timestamp,
            name: VideoNames[video.id] || video.id,
            emoji: '🎥'
        }))
    ]).sort((a, b) => b.timestamp - a.timestamp);

    // Group by date
    const groupedActivities = activities.reduce((acc, activity) => {
        const date = timestampToDay(activity.timestamp);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(activity);
        return acc;
    }, {} as { [key: string]: typeof activities });

    return (
        <StyledActivityTimeline>
            <div className="title">Activity History</div>
            <div className="timeline-content">
                {Object.entries(groupedActivities).map(([date, dayActivities]) => (
                    <StyledTimelineItem
                        key={date}
                        isHighlighted={date === selectedDate}
                    >
                        <div className="date">{formatDate(date)}</div>
                        <div className="activities">
                            {dayActivities.map((activity, index) => (
                                <div key={index} className="activity-item">
                                    {activity.emoji} {activity.name}
                                </div>
                            ))}
                        </div>
                    </StyledTimelineItem>
                ))}
            </div>
        </StyledActivityTimeline>
    );
};

// Add this interface after other interfaces
interface FormattedActivityReport {
    userInfo: {
        email: string;
        totalTests: number;
        totalVideos: number;
        testCompletion: number;
        videoCompletion: number;
    };
    testDetails: {
        testName: string;
        questionsTotal: number;
        questionsAnswered: number;
        percentageComplete: number;
        successRate: number;
    }[];
    recentActivity: {
        date: string;
        activities: {
            type: 'test' | 'video';
            name: string;
        }[];
    }[];
}

// Add this function before the Account component
const formatActivitiesForPDF = (
    moduleData: { [key: string]: ModuleData },
    userEmail: string,
    videoCount: number
): FormattedActivityReport => {
    // Calculate total tests and videos
    const totalTests = Object.values(moduleData).reduce((sum, module) =>
        sum + module.tests.filter(test => test.questionsAnswered > 0).length, 0);
    const totalVideos = Object.values(moduleData).reduce((sum, module) =>
        sum + module.videos.filter(video => video.seen).length, 0);

    // Format test details
    const testDetails = Object.values(moduleData)
        .flatMap(module => module.tests)
        .filter(test => test.questionsAnswered > 0)
        .map(test => ({
            testName: TestNames[String(test.testId) as keyof typeof TestNames] ||
                FullYearTestNames[String(test.testId) as keyof typeof FullYearTestNames] ||
                `Test ${test.testId}`,
            questionsTotal: test.questionsTotal || 0,
            questionsAnswered: test.questionsAnswered,
            percentageComplete: (test.questionsAnswered / (test.questionsTotal || 1)) * 100,
            successRate: (test.correctAnswers / test.questionsAnswered) * 100
        }))
        .sort((a, b) => b.successRate - a.successRate);

    // Get all activities and sort by timestamp
    const allActivities = Object.values(moduleData).flatMap(module => [
        ...module.tests.map(test => ({
            type: 'test' as const,
            timestamp: test.timestamp,
            name: TestNames[String(test.testId) as keyof typeof TestNames] ||
                FullYearTestNames[String(test.testId) as keyof typeof FullYearTestNames] ||
                `Test ${test.testId}`
        })),
        ...module.videos.map(video => ({
            type: 'video' as const,
            timestamp: video.timestamp,
            name: VideoNames[video.id] || video.id
        }))
    ]).sort((a, b) => b.timestamp - a.timestamp);

    // Group activities by date
    const recentActivity = allActivities.reduce((acc, activity) => {
        const date = timestampToDay(activity.timestamp);
        const existingDay = acc.find(day => day.date === date);

        if (existingDay) {
            existingDay.activities.push({
                type: activity.type,
                name: activity.name
            });
        } else {
            acc.push({
                date,
                activities: [{
                    type: activity.type,
                    name: activity.name
                }]
            });
        }
        return acc;
    }, [] as FormattedActivityReport['recentActivity']);

    return {
        userInfo: {
            email: userEmail,
            totalTests,
            totalVideos,
            testCompletion: (totalTests / DEFAULT_TEST_COUNT) * 100,
            videoCompletion: (totalVideos / videoCount) * 100
        },
        testDetails,
        recentActivity: recentActivity.slice(0, 30) // Last 30 days of activity
    };
};

// Add this interface for CSV data
interface ActivityCSVRow {
    userId: string;
    date: string;
    activityType: string;
    activityDescription: string;
    questionsTotal?: number;
    questionsAnswered?: number;
    percentageComplete?: number;
    successRate?: number;
}

interface TestActivity {
    userId: string;
    timestamp: number;
    activityType: string;
    activityDescription: string;
    questionsTotal: number;
    questionsAnswered: number;
    percentageComplete: number;
    successRate: number;
}

interface VideoActivity {
    userId: string;
    timestamp: number;
    activityType: string;
    activityDescription: string;
}

// Add this function to format data for CSV
const formatActivitiesForCSV = (
    moduleData: { [key: string]: ModuleData },
    userId: string
): ActivityCSVRow[] => {
    // Get all activities and sort by timestamp
    const testActivities: TestActivity[] = Object.values(moduleData)
        .flatMap(module => 
            module.tests
                .filter(test => test.questionsAnswered > 0) // Only include tests that have been taken
                .map(test => ({
                    userId,
                    timestamp: test.timestamp,
                    activityType: 'Test',
                    activityDescription: TestNames[String(test.testId) as keyof typeof TestNames] ||
                        FullYearTestNames[String(test.testId) as keyof typeof FullYearTestNames] ||
                        `Test ${test.testId}`,
                    questionsTotal: test.questionsTotal || 0,
                    questionsAnswered: test.questionsAnswered || 0,
                    percentageComplete: test.questionsTotal ? (test.questionsAnswered / test.questionsTotal) * 100 : 0,
                    successRate: test.questionsAnswered ? (test.correctAnswers / test.questionsAnswered) * 100 : 0
                }))
        );
    
    const videoActivities: VideoActivity[] = Object.values(moduleData).flatMap(module => 
        module.videos
            .filter(video => video.seen) // Only include videos that have been watched
            .map(video => ({
                userId,
                timestamp: video.timestamp,
                activityType: 'Video',
                activityDescription: VideoNames[video.id] || video.id
            }))
    );
    
    // Type guard function to check if an activity is a test activity
    const isTestActivity = (activity: TestActivity | VideoActivity): activity is TestActivity => {
        return activity.activityType === 'Test';
    };

    // First process tests (sorted by timestamp)
    const sortedTestActivities = testActivities
        .filter(activity => activity.timestamp) // Filter out null/undefined timestamps
        .sort((a, b) => a.timestamp - b.timestamp)
        .map(activity => {
            const result: ActivityCSVRow = {
                userId: activity.userId,
                date: activity.timestamp ? new Date(activity.timestamp).toLocaleDateString().replace(/,/g, ' ') : 'No Date',
                activityType: activity.activityType,
                activityDescription: activity.activityDescription,
                questionsTotal: activity.questionsTotal,
                questionsAnswered: activity.questionsAnswered,
                percentageComplete: parseFloat(activity.percentageComplete.toFixed(1)),
                successRate: parseFloat(activity.successRate.toFixed(1))
            };
            return result;
        });

    // Then process videos (sorted by timestamp)
    const sortedVideoActivities = videoActivities
        .filter(activity => activity.timestamp) // Filter out null/undefined timestamps
        .sort((a, b) => a.timestamp - b.timestamp)
        .map(activity => {
            const result: ActivityCSVRow = {
                userId: activity.userId,
                date: activity.timestamp ? new Date(activity.timestamp).toLocaleDateString().replace(/,/g, ' ') : 'No Date',
                activityType: activity.activityType,
                activityDescription: activity.activityDescription
            };
            return result;
        });

    // Return tests first, then videos
    return [...sortedTestActivities, ...sortedVideoActivities];
};

// First, update the StyledDownloadButton styling to be more responsive
const StyledDownloadButton = styled.button`
    padding: 8px 15px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 600;
    transition: background-color 0.2s;
    width: 170px;
    margin-bottom: 0;
    font-size: 0.9em;

    &:hover {
        background-color: #357abd;
    }

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
    
    @media ${deviceSize.mobileL} {
        width: 48%;
    }
`;

// Adjust the StyledButtonContainer for better positioning across all screen sizes
const StyledButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    margin-bottom: 15px;
    justify-content: flex-end;
    
    @media (min-width: 1200px) {
        justify-content: flex-end;
    }
    
    @media ${deviceSize.tablet} {
        justify-content: space-between;
    }
    
    @media ${deviceSize.mobileL} {
        justify-content: space-between;
        margin-bottom: 10px;
    }
`;

export const Account = () => {
    const { userId } = useParams();
    const [errorMessages, setErrorMessages] = useState<string>();
    const [userDateActivity, setUserDateActivity] = useState<AccountActivity[]>([]);
    const [userTakeTestCount, setUserTakeTestCount] = useState<number>(0);
    const [userTakeVideoCount, setUserTakeVideoCount] = useState<number>(0);
    const [userDateRange, setUserDateRange] = useState<{ start: string, end: string }>({ start: "", end: "" });
    const [userHasActivity, setUserHasActivity] = useState<boolean>(false);
    const [userSelectedActivity, setUserSelectedActivity] = useState<AccountActivity | null>(null);
    const [moduleData, setModuleData] = useState<{ [key: string]: ModuleData }>({});
    const [userEmail, setUserEmail] = useState<string>("Unknown");

    const {
        getUserTestData, videoCount,
        userEmail: contextUserEmail
    } = useContext<AccountContextInterface>(AccountContext);

    useEffect(() => {
        if (userId) {
            getUserTestData(userId, (error: string, data: UserModuleTestInfo | undefined, email?: string) => {
                if (error) {
                    console.error('Error:', error);
                    setErrorMessages(error);
                    return;
                }
                if (!data || Object.keys(data).length === 0) {
                    setErrorMessages(USER_NOT_FOUND);
                    return;
                }

                setModuleData(data);
                
                // Set email with priority: callback email > context email > "Unknown"
                if (email) {
                    setUserEmail(email);
                } else if (contextUserEmail) {
                    setUserEmail(contextUserEmail);
                } else {
                    setUserEmail("Unknown");
                }
                
                const modules = Object.keys(data);
                if (modules && modules.length > 0) {
                    const timestamps: number[] = [];
                    const videoTimestamps: number[] = [];
                    modules.forEach(module => {
                        const moduleData = data[module];
                        if (moduleData) {
                            setUserTakeTestCount(moduleData.tests.length);
                            moduleData.tests.forEach((test: TestData) => {
                                timestamps.push(test.timestamp);
                            });
                            setUserTakeVideoCount(moduleData.videos.length);
                            moduleData.videos.forEach((video: VideoData) => {
                                videoTimestamps.push(video.timestamp);
                            });
                        }
                    });
                    const sorted = [...timestamps, ...videoTimestamps].sort((a, b) => a - b);
                    const twoMonthsAgo = subtractTwoMonthsInSeconds(sorted[sorted.length - 1]);
                    const hasActivity = isWithinTwoMonths(sorted[sorted.length - 1]);
                    setUserHasActivity(hasActivity);
                    setUserDateRange({ start: timestampToDay(twoMonthsAgo), end: timestampToDay(sorted[sorted.length - 1]) })
                    const groupTestByDay = groupTimestampsByDay(timestamps).map((day: AccountActivities) => ({ ...day, tests: day.count, videos: 0 }));
                    const groupVideoByDay = groupTimestampsByDay(videoTimestamps).map((day: AccountActivities) => ({ ...day, videos: day.count, tests: 0 }));
                    const groupByDay = [...groupTestByDay, ...groupVideoByDay].reduce((p: { [day: string]: AccountActivity }, day: AccountActivity) => {
                        if (p[day.date]) {
                            p[day.date].count += day.count;
                            p[day.date].tests += day.tests;
                            p[day.date].videos += day.videos;
                        } else {
                            p[day.date] = day;
                        }
                        return p;
                    }, {});
                    const userDays = Object.values(groupByDay)
                    setUserDateActivity(userDays);
                }
            });
        }
    }, [userId, getUserTestData, contextUserEmail]);

    const DisplayUserActivity = () => {
        if (!userSelectedActivity) {
            return <StyledDayStats><div className="date">{HOVER_DAY}</div></StyledDayStats>;
        }

        // Get tests and videos for this date
        const dayTests = Object.values(moduleData)
            .flatMap(module => module.tests)
            .filter(test => timestampToDay(test.timestamp) === userSelectedActivity.date)
            .map(test => TestNames[String(test.testId) as keyof typeof TestNames] ||
                FullYearTestNames[String(test.testId) as keyof typeof FullYearTestNames] ||
                `Test ${test.testId}`);

        const dayVideos = Object.values(moduleData)
            .flatMap(module => module.videos)
            .filter(video => timestampToDay(video.timestamp) === userSelectedActivity.date)
            .map(video => VideoNames[video.id] || video.id);

        return (
            <StyledDayStats>
                <div className="date">{formatDate(userSelectedActivity.date)}</div>
                <div className="metrics">
                    <div className="metric">
                        <div className="value">{userSelectedActivity.tests}</div>
                        <div className="label">Tests Taken</div>
                    </div>
                    <div className="metric">
                        <div className="value">{userSelectedActivity.videos}</div>
                        <div className="label">Videos Watched</div>
                    </div>
                    <div className="metric">
                        <div className="value">{userSelectedActivity.count}</div>
                        <div className="label">Total Activities</div>
                    </div>
                </div>
                <StyledActivityList>
                    {dayTests.length > 0 && (
                        <>
                            {dayTests.map((test, index) => (
                                <div key={index} className="activity-item">📝 {test}</div>
                            ))}
                        </>
                    )}
                    {dayVideos.length > 0 && (
                        <>
                            {dayVideos.map((video, index) => (
                                <div key={index} className="activity-item">🎥 {video}</div>
                            ))}
                        </>
                    )}
                </StyledActivityList>
            </StyledDayStats>
        );
    }

    // Add this method to the Account component
    const prepareActivityReport = () => {
        if (!moduleData || !userEmail) {
            console.error('No data available for PDF generation');
            return null;
        }

        return formatActivitiesForPDF(moduleData, userEmail, videoCount);
    };

    // Move handleDownloadCSV inside the component
    const handleDownloadCSV = () => {
        if (!moduleData || !userId) {
            console.error('No data available for CSV generation');
            return;
        }

        try {
            const activities = formatActivitiesForCSV(moduleData, userId);
            
            // Create CSV content
            const headers = ['User Email', 'Date', 'Activity Type', 'Activity Description', 'Total Questions', 'Questions Answered', 'Completion %', 'Success Rate %'];
            const csvContent = [
                headers.join(','),
                ...activities.map(row => 
                    [
                        userEmail,
                        row.date.replace(/,/g, ' '),
                        row.activityType,
                        `"${row.activityDescription.replace(/"/g, '""')}"`,
                        row.questionsTotal !== undefined ? row.questionsTotal : '',
                        row.questionsAnswered !== undefined ? row.questionsAnswered : '',
                        row.percentageComplete !== undefined ? row.percentageComplete : '',
                        row.successRate !== undefined ? row.successRate : ''
                    ].join(',')
                )
            ].join('\n');

            // Create and trigger download with email in filename
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            
            // Format filename with email
            const safeEmail = userEmail && userEmail !== "Unknown" 
                ? userEmail.replace(/[^a-zA-Z0-9]/g, '_')  // Replace special chars with underscores
                : userId;
            
            link.setAttribute('href', url);
            link.setAttribute('download', `activity_report_${safeEmail}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error generating CSV:', error);
        }
    };

    // Add this function inside the Account component
    const handleDownloadPDF = () => {
        const activityReport = prepareActivityReport();
        if (!activityReport) {
            console.error('No data available for PDF generation');
            return;
        }

        try {
            // Create print content
            const printContent = document.createElement('div');
            
            // Include email in the title
            const emailForTitle = userEmail && userEmail !== "Unknown" 
                ? userEmail 
                : userId ? `User ID: ${userId}` : 'Unknown User';
            
            printContent.innerHTML = `
                <div id="print-content" style="padding: 20px;">
                    <h1 style="text-align: center;">Activity Report for ${emailForTitle}</h1>
                    
                    <h2>User Information</h2>
                    <p>Email: ${activityReport.userInfo.email}</p>
                    <p>Tests Completed: ${activityReport.userInfo.totalTests}</p>
                    <p>Videos Watched: ${activityReport.userInfo.totalVideos}</p>
                    <p>Test Completion: ${activityReport.userInfo.testCompletion.toFixed(1)}%</p>
                    <p>Video Completion: ${activityReport.userInfo.videoCompletion.toFixed(1)}%</p>
                    
                    <h2>Test Performance</h2>
                    <table style="width: 100%; border-collapse: collapse;">
                        <tr>
                            <th style="text-align: left; padding: 8px; border: 1px solid #ddd;">Test Name</th>
                            <th style="text-align: right; padding: 8px; border: 1px solid #ddd;">Total Questions</th>
                            <th style="text-align: right; padding: 8px; border: 1px solid #ddd;">Questions Answered</th>
                            <th style="text-align: right; padding: 8px; border: 1px solid #ddd;">Completion</th>
                            <th style="text-align: right; padding: 8px; border: 1px solid #ddd;">Success Rate</th>
                        </tr>
                        ${activityReport.testDetails.map(test => `
                            <tr>
                                <td style="padding: 8px; border: 1px solid #ddd;">${test.testName}</td>
                                <td style="text-align: right; padding: 8px; border: 1px solid #ddd;">${test.questionsTotal || 'N/A'}</td>
                                <td style="text-align: right; padding: 8px; border: 1px solid #ddd;">${test.questionsAnswered}</td>
                                <td style="text-align: right; padding: 8px; border: 1px solid #ddd;">${test.percentageComplete.toFixed(1)}%</td>
                                <td style="text-align: right; padding: 8px; border: 1px solid #ddd;">${test.successRate.toFixed(1)}%</td>
                            </tr>
                        `).join('')}
                    </table>
                      <h2>Recent Activity</h2>
                    ${activityReport.recentActivity.map(day => `
                        <div class="activity-date"><b>${formatDate(day.date)}</b></div>
                        ${day.activities.map(activity => `
                            <div class="activity-item">
                                ${activity.type === 'test' ? '📝' : '🎥'} ${activity.name}
                            </div>
                        `).join('')}
                    `).join('')}
                </div>
            `;

            // Add print styles
            const style = document.createElement('style');
            style.textContent = `
                @media print {
                    body * {
                        visibility: hidden;
                    }
                    #print-content, #print-content * {
                        visibility: visible;
                    }
                    #print-content {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            `;

            // Add elements to document
            document.body.appendChild(style);
            document.body.appendChild(printContent);

            // Print
            window.print();

            // Cleanup
            document.body.removeChild(style);
            document.body.removeChild(printContent);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    return (
        <div>
            <AppTitle
                title='Student Account Activity'
                description={userEmail && userEmail !== "Unknown" 
                    ? `Email: ${userEmail}` 
                    : userId ? `User ID: ${userId}` : ''}
            />
            <ContentBox>
                <StyledTest>
                    <StyledChartContainer>
                        {userHasActivity
                            ? <StyledChartHeatmap>
                                <StyledButtonContainer>
                                    <StyledDownloadButton
                                        onClick={handleDownloadCSV}
                                        disabled={!moduleData}
                                    >
                                        📥 CSV Report
                                    </StyledDownloadButton>
                                    <StyledDownloadButton
                                        onClick={handleDownloadPDF}
                                        disabled={!moduleData}
                                    >
                                        📄 PDF Report
                                    </StyledDownloadButton>
                                </StyledButtonContainer>
                                <CalendarComponent
                                    showMonthLabels={true}
                                    showWeekdayLabels={true}
                                    gutterSize={1}
                                    startDate={new Date(userDateRange.start)}
                                    endDate={new Date(userDateRange.end)}
                                    values={userDateActivity}
                                    monthLabelsHeight={20}
                                    weekdayLabelsHeight={20}
                                    onClick={(value: any) => {
                                        if (value) {
                                            setUserSelectedActivity(value);
                                        }
                                    }}
                                    classForValue={(value: any) => {
                                        if (!value) {
                                            return 'color-empty';
                                        }
                                        return `color-scale-${value.count}`;
                                    }}
                                />
                                <div><DisplayUserActivity /></div>
                                {userHasActivity && (
                                    <ActivityTimeline
                                        moduleData={moduleData}
                                        selectedDate={userSelectedActivity?.date || null}
                                    />
                                )}
                            </StyledChartHeatmap>
                            : <StyledNoUserActivity>{errorMessages ? errorMessages : NO_ACTIVITY}</StyledNoUserActivity>}
                    </StyledChartContainer>
                    <StyledRightColumn>
                        <DashboardSectionInfo
                            mainTitle={`Practice Exams (Category & Full)`}
                            smallTitle={`Exam`}
                            readonly
                            info={{
                                started: userTakeTestCount,
                                notStarted: DEFAULT_TEST_COUNT - userTakeTestCount,
                                lastUserTest: null,
                                lastTest: null,
                            }}
                        />
                        <DashboardSectionInfo
                            mainTitle={`Lecture Videos`}
                            smallTitle={`Video`}
                            readonly
                            info={{
                                started: userTakeVideoCount,
                                notStarted: videoCount - userTakeVideoCount,
                            }}
                        />
                        {userHasActivity && (
                            <TestReport
                                tests={Object.values(moduleData)
                                    .flatMap(module => module.tests)
                                    .filter(test => test.questionsAnswered > 0)
                                }
                            />
                        )}
                    </StyledRightColumn>
                </StyledTest>
            </ContentBox>
        </div>
    );
}