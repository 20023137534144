import {
  UserModuleTestInfo,
  AccountContextInterface,
} from "../types";
import { useContext, useState, useCallback } from "react";
import React from "react";
import { FirebaseContext } from "./firebaseContext";
import { deepClone } from "../helpers/structures";
import { VIDEOS } from "../video-links";

export const AccountContext = React.createContext<AccountContextInterface>({
  userModuleTestInfo: {},
  getUserTestData: () => { },
  userId: "",
  loaded: false,
  videoCount: 0,
  userEmail: "",
});

interface AccountProviderProps {
  children: React.ReactNode;
}

function AccountProvider(props: AccountProviderProps) {
  const { firebase } = useContext(FirebaseContext);
  const [userId, setUserId] = useState<string>("");
  const [storedUserData, setStoredUserData] = useState<UserModuleTestInfo>({});
  const [userEmail, setUserEmail] = useState<string>("");

  const getUserTestData = useCallback((userId: string, callback: Function) => {
    setUserId(userId);
    try {
      // First try to get email from users collection
      firebase.doGetUserFromCollection(userId, (userData: any) => {
        // Extract email from users collection if available
        const userEmail = userData?.email || '';
        setUserEmail(userEmail);
        
        // Then get test data
        firebase.doRetrieveUserData(userId, (data: object) => {
          const userModuleTestInfo = data as UserModuleTestInfo;
          const userData = deepClone<UserModuleTestInfo>(userModuleTestInfo);
          setStoredUserData(userData);
          
          if (callback) callback(null, userData, userEmail);
        });
      });
    } catch (e) {
      console.error('Error in getUserTestData:', e);
      if (callback) callback(`User's data could not be retrieved, ${e}`);
    }
  }, [firebase]);

  const userItems = {
    getUserTestData,
    userModuleTestInfo: storedUserData,
    loaded: Object.keys(storedUserData).length > 0,
    userId,
    videoCount: VIDEOS.reduce((acc, curr) => acc + curr.videos.length, 0),
    userEmail,
  };

  return <AccountContext.Provider value={userItems}>{props.children}</AccountContext.Provider>;
}

export default AccountProvider;
