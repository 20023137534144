import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updatePassword, Auth } from "firebase/auth";
import { getFirestore, collection, doc, setDoc, getDoc, getDocs, addDoc, deleteDoc, query, where, orderBy, Firestore, QueryDocumentSnapshot } from "firebase/firestore";
import { getDatabase, Database } from "firebase/database";
import { CbprepSku, Flashcard, Question, TestsFlashcards, UserPurchases } from "../types";
import { COLLECTIONS, SKUS } from "../constants";
import { TEST_TYPES } from '../components/admin-tests';
import { Test } from './../types';
import testOrder from '../assets/data/testOrder.json';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

interface ModuleTestQandFlash {
  [moduleName: string]: { test: Question[]; flashcards: Flashcard[] };
}

class Firebase {
  auth: Auth;
  database: Database;
  firestore: Firestore;
  isLoggedIn: boolean;

  constructor() {
    const app = initializeApp(config);
    this.auth = getAuth(app);
    this.database = getDatabase(app);
    this.firestore = getFirestore(app);
    this.isLoggedIn = false;
  }

  doStoreTestFlashcards = (moduleName: string, data: TestsFlashcards) => {
    setDoc(doc(this.firestore, COLLECTIONS.MODULES, moduleName), data)
      .catch(e => console.log(e));
  };

  doStoreUserData = (id: string, data: object, callback: Function) => {
    if (id) {
      try {
        setDoc(doc(this.firestore, COLLECTIONS.USER_DATA, id), {
          ...data,
        })
          .then(() => callback())
          .catch(error => {
            console.log(error)
            callback("Save failed");
          });
      } catch (e) {
        callback(`Error:` + e);
      }
    }
  };

  doRetrieveAllUserIds(callback: Function) {
    getDocs(collection(this.firestore, COLLECTIONS.USER_MODULES))
      .then(querySnapshot => {
        callback(querySnapshot.docs.map(d => d.data()));
      });
  }

  doRetrieveUserCbpSku(id: string, callback: Function) {
    getDoc(doc(this.firestore, COLLECTIONS.USER_MODULES, id))
      .then(docSnapshot => {
        if (docSnapshot.exists()) {
          let userModuleTestInfo = docSnapshot.data() as any;
          let keys = Object.keys(userModuleTestInfo) as CbprepSku[]
          let cbprepSku
          let expiration
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (SKUS.includes(key)) {
              cbprepSku = key
              expiration = userModuleTestInfo[key].expiration
              break;
            }
          }
          callback(cbprepSku, expiration);
        } else {
          callback(null, null);
        }
      });
  }

  doRetrieveUserData(id: string, callback: Function) {
    getDoc(doc(this.firestore, COLLECTIONS.USER_DATA, id))
      .then(docSnapshot => {
        const userModuleTestInfo = docSnapshot.exists() ? docSnapshot.data() : {};
        callback(userModuleTestInfo);
      });
  }

  doUpdateUserPurchases(userPurchases: UserPurchases, callback: Function) {
    const email = userPurchases.email;
    const usersRef = collection(this.firestore, COLLECTIONS.USER_MODULES);
    const q = query(usersRef, where("email", "==", email));

    getDocs(q)
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw new Error("ERROR - no existing users with same email address detected");
        } else if (querySnapshot.size !== 1) {
          throw new Error("ERROR - multiple existing users with same email address detected");
        }

        const userDoc = querySnapshot.docs[0];
        let existingDocID = userDoc.id;
        console.log("user id", existingDocID);

        let newPurchasesData = { ...userPurchases };
        const userDocRef = doc(this.firestore, COLLECTIONS.USER_MODULES, existingDocID);

        return setDoc(userDocRef, newPurchasesData);
      })
      .then(() => callback())
      .catch(e => callback(e));
  }

  doDeleteUserData(email: string, callback: Function) {
    getDocs(query(collection(this.firestore, COLLECTIONS.USER_MODULES), where("email", "==", email)))
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw (new Error("ERROR - no existing user found to delete"));
        } else if (querySnapshot.size !== 1) {
          throw (new Error("ERROR - can not delete, multiple existing users found"));
        }
        deleteDoc(querySnapshot.docs[0].ref)
          .then(() => callback())
      })
      .catch(e => callback(e));
  }

  doAddNewUserPurchases(userPurchases: UserPurchases, callback: Function) {
    const password = String(userPurchases.email).toLowerCase();
    createUserWithEmailAndPassword(this.auth, String(userPurchases.email), password)
      .then(userCredential => {
        if (userCredential && userCredential.user) {
          setDoc(doc(this.firestore, COLLECTIONS.USER_MODULES, userCredential.user.uid), userPurchases)
            .then(() => callback())
        } else {
          console.error("Could not create user");
        }
      })
      .catch(e => callback(e));
  }

  doSendForgotPasswordEmail(emailAddress: string) {
    sendPasswordResetEmail(this.auth, emailAddress)
      .then(() => {
        console.log("Password Reset email sent!");
      })
      .catch(function (error) {
        console.error("Password Reset email failed!");
      });
  }

  doRetreiveTests(moduleNames: string[], callback: Function) {
    const errorState = moduleNames.reduce((p, m) => ({ ...p, [m]: { test: [], flashcards: [] } }), {});
    try {
      const testQandFPromises: Promise<ModuleTestQandFlash>[] = moduleNames.map(m => {
        return this.fetchTestQandFlashcards(m, this.firestore)
      });
      Promise.all(testQandFPromises)
        .then(tests => {
          let moduleTests = tests.reduce((p, t) => Object.assign(p, t), {});
          callback(moduleTests);
        })
        .catch((e: any) => {
          callback(errorState);
        });
    } catch (e) {
      callback(errorState);
    }
  }

  doAddTest(testType: string, testData: Test, callback: Function) {
    let collectionName: string
    if (testType === TEST_TYPES.CAT) collectionName = COLLECTIONS.CATEGORY_TESTS
    else if (testType === TEST_TYPES.FULL) collectionName = COLLECTIONS.FULL_EXAMS
    else throw new Error('add test; invalid test type')
    addDoc(collection(this.firestore, collectionName), testData)
      .then(() => {
        callback()
      })
      .catch(e => callback(e));
  }

  doDeleteTest(testType: string, testId: number, callback: Function) {
    let collectionName: string
    if (testType === TEST_TYPES.CAT) {
      collectionName = COLLECTIONS.CATEGORY_TESTS
    }
    else if (testType === TEST_TYPES.FULL) {
      collectionName = COLLECTIONS.FULL_EXAMS
    }
    else throw new Error('update test: invalid test type')
    getDocs(query(collection(this.firestore, collectionName), where("id", "==", testId)))
      .then(querySnapshot => {
        if (querySnapshot.size !== 1) {
          throw (new Error("ERROR - multiple tests with same id detected"));
        }
        deleteDoc(querySnapshot.docs[0].ref)
          .then(() => callback())
      })
      .catch(e => callback(e));
  }

  doUpdateTest(testType: string, testData: Test, callback: Function) {
    let collectionName: string;
    if (testType === TEST_TYPES.CAT) {
      collectionName = COLLECTIONS.CATEGORY_TESTS;
    }
    else if (testType === TEST_TYPES.FULL) {
      collectionName = COLLECTIONS.FULL_EXAMS;
    }
    else {
      return callback(new Error('update test: invalid test type'));
    }

    const testsRef = collection(this.firestore, collectionName);
    const q = query(testsRef, where("id", "==", testData.id));

    getDocs(q)
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw new Error("ERROR - no test with same id detected");
        } else if (querySnapshot.size !== 1) {
          throw new Error("ERROR - multiple tests with same id detected");
        }

        const testDoc = querySnapshot.docs[0];
        let existingDocID = testDoc.id;
        const testDocRef = doc(this.firestore, collectionName, existingDocID);

        return setDoc(testDocRef, testData);
      })
      .then(() => callback())
      .catch(e => callback(e));
  }

  doAddFlashcard(flashcardData: Flashcard, callback: Function) {
    addDoc(collection(this.firestore, COLLECTIONS.FLASHCARDS), flashcardData)
      .then(() => {
        callback()
      })
      .catch(e => callback(e));
  }

  doUpdateFlashcard(flashcardData: Flashcard, callback: Function) {
    const flashcardsRef = collection(this.firestore, COLLECTIONS.FLASHCARDS);
    const q = query(flashcardsRef, where("id", "==", flashcardData.id));

    getDocs(q)
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw new Error("ERROR - no flashcard with same id detected");
        } else if (querySnapshot.size !== 1) {
          throw new Error("ERROR - multiple flashcards with same id detected");
        }

        const flashcardDoc = querySnapshot.docs[0];
        let existingDocID = flashcardDoc.id;
        const flashcardDocRef = doc(this.firestore, COLLECTIONS.FLASHCARDS, existingDocID);

        return setDoc(flashcardDocRef, flashcardData);
      })
      .then(() => callback())
      .catch(e => callback(e));
  }

  doDeleteFlashcard(flashcardId: number, callback: Function) {
    getDocs(query(collection(this.firestore, COLLECTIONS.FLASHCARDS), where("id", "==", flashcardId)))
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw (new Error("ERROR - no flashcards with same id detected"));
        } else if (querySnapshot.size !== 1) {
          throw (new Error("ERROR - multiple flashcards with same id detected"));
        }
        deleteDoc(querySnapshot.docs[0].ref)
          .then(() => callback())
      })
      .catch(e => callback(e));
  }

  fetchTestQandFlashcards(moduleName: string, firestore: Firestore): Promise<ModuleTestQandFlash> {
    return new Promise(function (resolve, reject) {
      try {
        getDocs(collection(firestore, COLLECTIONS.CATEGORY_TESTS))
          .then((catTestDataSnapshot) => {
            getDocs(collection(firestore, COLLECTIONS.FULL_EXAMS))
              .then((fullExamDataSnapshot) => {
                const sortedCat = catTestDataSnapshot.docs
                  .map((d: QueryDocumentSnapshot) => d.data() as Test)
                  .sort((a: Test, b: Test) => {
                    const idxA = testOrder.chapter.indexOf(a.id)
                    const idxB = testOrder.chapter.indexOf(b.id)
                    if (idxA === -1 || idxB === -1)
                      return idxA + idxB
                    else
                      return idxA - idxB
                  })

                const sortedFull = fullExamDataSnapshot.docs
                  .map((d: QueryDocumentSnapshot) => d.data() as Test)
                  .sort((a: Test, b: Test) => {
                    const idxA = testOrder.fullYear.indexOf(a.id)
                    const idxB = testOrder.fullYear.indexOf(b.id)
                    return idxA - idxB
                  })

                getDocs(query(collection(firestore, COLLECTIONS.FLASHCARDS), orderBy('id')))
                  .then((flashcardDataSnapshot) => {
                    const flashcardData = flashcardDataSnapshot.docs
                      .map((d: QueryDocumentSnapshot) => d.data() as Flashcard)

                    resolve({
                      [moduleName]: {
                        flashcards: flashcardData,
                        tests: {
                          chapter: sortedCat,
                          fullYear: sortedFull
                        }
                      }
                    } as any)
                  })
              })
              .catch((e: any) => {
                reject(e);
              });
          })
          .catch((e: any) => {
            reject(e);
          });
      } catch (e) {
        reject(e);
      }
    })
  }

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = (email: string, password: string, callback: Function) =>
    signInWithEmailAndPassword(this.auth, email, password)
      .then(userCredential => {
        this.isLoggedIn = true;
        callback(null, userCredential.user);
      })
      .catch(e => {
        callback(e, null);
      })
      .finally(() => {
        console.log("login attempted");
      });

  doSignOut = () =>
    signOut(this.auth)
      .then(() => (this.isLoggedIn = false))
      .catch(() => console.error("user could not logout"));

  doPasswordReset = (email: string) => sendPasswordResetEmail(this.auth, email);

  doPasswordUpdate = (password: string) =>
    this.auth.currentUser ? updatePassword(this.auth.currentUser, password) : console.error("user not active");

  doPasswordUpdateToDefaultPassword = (password: string) =>
    this.auth.currentUser ? updatePassword(this.auth.currentUser, password) : console.error("user not active");

  doGetUserEmail = (userId: string, callback: Function) => {
    getDoc(doc(this.firestore, COLLECTIONS.USER_MODULES, userId))
        .then(docSnapshot => {
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                callback(userData.email);
            } else {
                callback(null);
            }
        })
        .catch(error => {
            console.error("Error getting user email:", error);
            callback(null);
        });
  };

  doGetUserFromCollection = (userId: string, callback: Function) => {
    getDoc(doc(this.firestore, COLLECTIONS.USER_MODULES, userId))
      .then(docSnapshot => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          callback(userData);
        } else {
          callback(null);
        }
      })
      .catch(error => {
        console.error("Error getting user from collection:", error);
        callback(null);
      });
  };
}

export default Firebase;