import React from 'react'
import styled from 'styled-components'

const StyledTestInfo = styled.div`
`

/**
 * How to add a link to an announcement, here is an example:
 *  {AddLink("1124update.pdf", "Click here for the update")}
 */
const AddLink = (filename: string, text: string) => {
  return <a target="_blank" rel="noopener noreferrer" href={ require(`../assets/extra/${filename}`) }>{ text } </a>
}


export const TestInfo = () => {
  return (
    <StyledTestInfo>
      <b>
       Announcements and stuff you should know
      </b>
      <p>        
       <b>4.1.25
       </b>
        </p>
      <p>A couple of thoughts and ideas as we get closer to the exam.        
      </p>
      <p>CBP provides access to a sample exam.  I recommend that you actually look at this.  It will help you learn what 
         will happen on exam day.  You can find the exam at one of the drop down menus at the CBP exam info page. 
         You kind of have to mimic an actual registration just like exam day!  "Preparing for the CBLE - Sample Exam".
        <a href="https://www.cbp.gov/trade/programs-administration/customs-brokers/license-examination-notice-examination/cble-registration-information" target="_blank">Click here</a> </p>
       <p></p>
       <p>I do recommend that you look at the sample questions at my online test center - Multifact or Practical exam
       questions.  There are normally at least two Multifact / practical type questions on each exam.
       </p>
      <p>        
      <b>2.3.25
       </b>
        </p>
      <p>
        I have received emails and calls asking if the new Trump tariffs will impact the April exam.
        NO....  No the new Trump tariffs will not impact the April exam.  I do have a video lecture about 
        the original Trump / China tariffs and how they work.  CBP has not had a single question about the
        old Trump / China tariffs...   but, probabably will someday.
        
        gistration information...  <a href="https://www.cbp.gov/trade/programs-administration/customs-brokers/license-examination-notice-examination/cble-registration-information" target="_blank">Click here</a>
      </p>
      <p>
      <b>11.20.24
      </b>  
      </p>
      <p>Update to the study guide.  If you joined me on November 14th or before, you
        need this update.  Please download the PDF, print double sided, and replace the
        pages in your study guide.  There are NO major updates, just a few small items
        to clean up.  {AddLink("1124update.pdf", "Click here for the update")} &nbsp;
        Of course contact me if you have any questions.
      </p>
       <p>
         <b>Space reserved for future great stuff
      </b>  
      </p>
      <p>
      <b>
       </b>  
      </p>
      <p>
      </p>
      <p>
      </p>
    </StyledTestInfo>
  )
}
