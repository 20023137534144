import React, { useContext, useEffect, useMemo, useState } from "react";
import { StoreConextInterface, Test, UserTest } from "../types";
import { StoreContext } from "../contexts/storeContext";
import styled from "styled-components";
import {
  AppTitle,
  ContentBox,
  deviceSize,
  regText,
} from "./common";
import { TestInfo } from "./announcements";
import { FLASHCARD_ONLY, FULL_ACCESS, ROUTES, TESTS_AND_FLASHCARDS, TEST_ONLY } from "../constants";
import { Navigate, useNavigate } from "react-router-dom";

const StyledTest = styled.div`
  display: flex;

  > div {
    width: 50%;
    padding: 0 15px;
  }
  @media ${deviceSize.tablet} {
    flex-direction: column;
    padding:0 25px;
    > div {
      width: 100%;
      padding: 0; 
    }
    > div:last-of-type {
      margin-top: 25px;
    }
  }
`;

const StyledInfoLink = styled.div`
    display: flex;
    justify-content: space-between;

`

const StyledSectionMultiLink = styled.div`
  display: flex;
  flex-flow: column;
  border-top: 2px black solid;
  padding: 15px 0;
  width: 100%;

  button{
    border: none;
    background-color: inherit;
    color: #016BAC;
    font-weight: bold;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
  }

  .title{
    font-weight: bold;
    font-size: ${regText};
    padding: 0 0 5px 0;
  }

  .subTitle {
    margin: 10px 0;
  }

  .info{
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    .infoBox{
      .count{
        font-weight: bold;
      }
    }
  }

`

const StyledDashboardSection = styled.div`
  border-top: 2px black solid;
  padding: 15px 0;
  width: 100%;

  button{
    border: none;
    background-color: inherit;
    color: #016BAC;
    font-weight: bold;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
  }

  .flex{
    display: flex;
    justify-content: space-between;
    .title{
      font-weight: bold;
      font-size: ${regText};
    }
  }
  .info{
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    .infoBox{
      .count{
        font-weight: bold;
      }
    }
  }
`;


const DashboardSectionMultiLink = ({
  title,
  subTitle,
  infoLinks,
}: {
  title: string,
  subTitle: string,
  infoLinks: { info: string, link?: string, newBrowserTab?: boolean }[]
}) => {
  const navigate = useNavigate();
  const handleClick = (link: string, newBrowserTab: boolean) => {
    if (newBrowserTab) {
      window.open(link, '_blank')
    } else if (link) {
      navigate(link)
    }
  }
  return <StyledSectionMultiLink>
    <div className="title">{title}</div>
    {subTitle && <div className="subTitle">{subTitle}</div>}
    {infoLinks.map(({ info, link, newBrowserTab }, i) => {
      return (
        <StyledInfoLink key={`${link}-${i}`}>
          <span>{info}</span>
          {link && <button onClick={() => handleClick(link, !!newBrowserTab)}>{'Go >'}</button>}
        </StyledInfoLink>)
    }
    )}

  </StyledSectionMultiLink>
}

export const DashboardSectionSmall = ({
  title,
  link,
  newBrowserTab,
  info
}: {
  title: string
  link?: string
  info?: any
  newBrowserTab?: boolean
}) => {
  const navigate = useNavigate();
  const hasLink = link || newBrowserTab;
  const handleClick = () => {
    if (newBrowserTab) {
      window.open(link, '_blank')
    } else if (link) {
      navigate(link)
    }
  }
  return <StyledDashboardSection>
    <div className='flex'>
      <div className="title">{title}</div>
      {hasLink && <button onClick={handleClick}>{'Go >'}</button>}
    </div>
    {info}
  </StyledDashboardSection>
}

export const DashboardSectionInfo = ({
  mainTitle,
  smallTitle,
  route,
  info,
  readonly
}: {
  mainTitle: string
  smallTitle: string
  route?: string
  info: any
  readonly?: boolean
}) => {
  const navigate = useNavigate();

  const handleResumeClick = () => {
    if (route === ROUTES.VIDEOS) {
      navigate(route, {
        state: {
        resumeVideo: true 
        }
      })
    } else if (route === ROUTES.TESTS) {
      if (info.lastTest && info.lastUserTest) {
        navigate(ROUTES.TAKE,{
          state: {
            test: info.lastTest,
            userTest: info.lastUserTest
          }
        })
      } else if (route) {
        navigate(route)
      }
    }
  }

  return <StyledDashboardSection>
    <div className='flex'>
      <div className="title">{mainTitle}</div>
      {!readonly && <button onClick={handleResumeClick}>{`Resume Last ${smallTitle} >`}</button>}
    </div>
    <div className='info'>

      <div className="infoBox">
        <div className="count">{info.notStarted}</div>
        <div>not started</div>
      </div>

      <div className="infoBox">
        <div className="count">{info.started}</div>
        <div>started</div>
      </div>

      {route && <button onClick={() => navigate(route)}>{`View All >`}</button>}
    </div>
  </StyledDashboardSection>
}

export const Dashboard = () => {
  const {
    access, userVideoData, videos, userTestData, testsFlashcards
  } = useContext<StoreConextInterface>(StoreContext);

  let videoCount = 0
  videos.map(videoCategory => {
    videoCount += videoCategory.videos.length
  })

  const [lastUserTest, setLastUserTest] = useState<UserTest | undefined>(undefined)
  useEffect(() => {
    if (userTestData && userTestData.length > 0) {
      const userTest = userTestData.reduce(function (prev, current) {
        return (prev.timestamp > current.timestamp) ? prev : current
      })
      setLastUserTest(userTest)
    }
  }, [userTestData])

  const [lastTest, setLastTest] = useState<Test | undefined>(undefined)
  useEffect(() => {
    if (lastUserTest) {
      let test
      test = testsFlashcards.tests.chapter.find(x => x.id === lastUserTest.testId)
      if (!test) {
        test = testsFlashcards.tests.fullYear.find(x => x.id === lastUserTest.testId)
      }
      setLastTest(test)
    }
  }, [lastUserTest, testsFlashcards])

  const studyGuide: string = require('../assets/extra/Ch1_ 8studyguide.pdf')
  const [testCount, setTestCount] = useState<number>(0)
  useEffect(() => {
    setTestCount(testsFlashcards.tests.chapter.length + testsFlashcards.tests.fullYear.length)
  }, [testsFlashcards])

  const NavButtons = useMemo(() => {
    if (access === FULL_ACCESS) {
      return <div>
        <DashboardSectionInfo
          mainTitle={`Practice Exams`}
          smallTitle={`Exam`}
          route={ROUTES.TESTS}
          info={{
            started: userTestData.length,
            notStarted: testCount === 0 ? 0 : testCount - userTestData.length,
            lastUserTest: lastUserTest,
            lastTest: lastTest,
          }}
        />
        <DashboardSectionInfo
          mainTitle={`Lecture Videos`}
          smallTitle={`Video`}
          route={ROUTES.VIDEOS}
          info={{
            started: userVideoData.length,
            notStarted: videoCount - userVideoData.length,
          }}
        />
        <DashboardSectionSmall
          title='Class Notes'
          link={ROUTES.CLASSNOTES}
        />
        <DashboardSectionSmall
          title='Flash Cards'
          link={ROUTES.FLASHCARDS}
        />
        <DashboardSectionSmall
          title='Study Guide PDF CH 1-8. Get started before the hard copy arrives.'
          newBrowserTab={true}
          link={studyGuide}
        // link='../assets/extra/Ch1_ 8studyguide.pdf'
        />
        {/* <NavButton onClick={() => { window.open(, '_blank') }}>Download PDF of ch 1-8 of Study Guide until your copy arrives</NavButton> */}
        <DashboardSectionSmall
          title='Extra Information'
          link={ROUTES.EXTRAINFO}
        />

      <DashboardSectionMultiLink
        title='Study Guide - Updates'
        subTitle='You need this update if you joined before November 14, 2024'
        infoLinks={[
          {
            info: 'Click GO for the update',
            link: require("../assets/extra/1124update.pdf"),
            newBrowserTab: true
          }]}
          />          
      </div>
    }
  }, [access, lastUserTest, lastTest, testCount])

  if (access === FLASHCARD_ONLY) return <Navigate to={ROUTES.FLASHCARDS} />
  else if (access === TEST_ONLY || access === TESTS_AND_FLASHCARDS) return <Navigate to={ROUTES.TESTS} />
  else {
    return (
      <div>
        <AppTitle
          title='CBPrepCourse Student Dashboard'
          description=''
        />
        <ContentBox>
          <StyledTest>
            <div>
              {NavButtons}
            </div>
            <div>
              <TestInfo />
            </div>
          </StyledTest>
        </ContentBox>
      </div>
    );
  }
};
